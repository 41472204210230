import React from 'react';
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import resetIcon from './svg/reset';

const addResetButton = function() {
  const observer = new MutationObserver(function() {
    if (document.querySelector('.Twilio-MessageInput-default')) {
          observer.disconnect();
          const messageInput = document.querySelector('.Twilio-MessageInputArea-TextArea');
          let reset_button = document.createElement('button');
          const messageInputParent = messageInput.parentElement;
          reset_button.innerHTML = `<span>Reset this chat</span>${resetIcon}`;
          reset_button.classList = "reset-button";
          reset_button.addEventListener('click', () => window.location.reload());
          reset_button = messageInputParent.insertBefore(reset_button, messageInput)
      }
  });

  observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});
}

class App extends React.Component {

  state = {};

  constructor(props) {
    super(props);

    const { configuration } = props;
    FlexWebChat.Manager.create(configuration)
      .then(manager => this.setState({ manager }))
      .catch(error => this.setState({ error }));

    FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = {
      body: `Hello!  I’m the NABS Support Bot.  I’m glad you reached out.  How can we help you today?

        You can either let me know how you’re feeling, ask to find out more about any one of our services, or ask to see our case studies to find out more about the types of ways we can help people.

        Our services include: therapy, coaching (including stress coaching and wellbeing coaching), creative portfolio review, our Advice Line, mentoring, redundancy guidance, talks and masterclasses, financial support, grants and a knowledge hub with useful resources.

        To help me best answer your query, please enter single words or short phrases rather than long phrases.
        `,
      authorName: "NABS Bot"
    }

    FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
    FlexWebChat.Actions.invokeAction("RestartEngagement");

    // Add privacy policy link to intro screen
    FlexWebChat.PreEngagementCanvas.Content.add(
      <div className="privacy-policy" key="privacypolicy">
        <a target="_blank" rel="noopener noreferrer" href="https://nabs.org.uk/privacy-policy/">Privacy Policy</a>
      </div>
    );
  }

  render() {
    const { manager, error } = this.state;
    if (manager) {
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }

  componentDidMount() {
    addResetButton();
  }
}

export default App;
